<template>
  <div>
    <b-row
      class="orderAttachTab mx-0"
    >
      <b-col
        class="px-0"
      >
        <div v-if="files !== '' && files.length > 0">
          <b-row
            v-for="(file, index) in files"
            :key="index"
            class="orderAttachItem p-50"
            :class="(index % 2 == 0) ? 'itemEven' : 'itemOdd'"
          >
            <b-col
              cols="12"
              sm="10"
              class="d-flex justify-content-start align-self-center"
              @click="downloadFile(index)"
            >
              <svg-icon
                type="mdi"
                style="color: #005C96"
                :path="mdiFileDocumentOutline"
              />
              <span class="ml-1">{{ file.name }}</span>
            </b-col>
            <b-col
              cols="12"
              sm="2"
              class="d-flex justify-content-end align-self-center"
            >
              <b-button
                v-if="!readonly"
                class="btn-icon rounded-circle"
                variant="flat-info"
                size="md"
                @click="showConfirmDeleteAttach(index)"
              >
                <svg-icon
                  type="mdi"
                  size="18"
                  :path="mdiTrashCanOutline"
                />
              </b-button>
            </b-col>
          </b-row>
        </div>
        <b-row
          v-else
        >
          <b-col
            class="d-flex justify-content-center align-self-center mt-3"
          >
            <p>Não existem anexos adicionados.</p>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <b-row
      v-if="!(readonly)"
    >
      <b-col
        class="d-flex justify-content-end addOrderAttachBtn"
      >
        <input
          id="add_file"
          ref="fileInput"
          type="file"
          class="d-none"
          @change="handleFile"
        >
        <div>
          <b-button
            id="addOrderAttachBtn"
            v-b-tooltip.hover
            class="btn-icon rounded-circle mr-3 mb-2 mt-1"
            variant="primary"
            size="md"
            title="Adicionar Anexo"
            @click="selectFile()"
          >
            <svg-icon
              type="mdi"
              :path="mdiPlus"
            />
          </b-button>
        </div>
      </b-col>
    </b-row>
  </div>
</template>
<script>

import {
  BRow,
  BCol,
  BButton,
  VBTooltip,
} from 'bootstrap-vue'

import {
  useToast,
} from 'vue-toastification/composition'

import {
  mdiFileDocumentOutline,
  mdiPlus,
  mdiTrashCanOutline,
} from '@mdi/js'

import SvgIcon from '@jamescoyle/vue-icon'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import syslic from '@/syslic'

export default {
  components: {
    BRow,
    BCol,
    BButton,
    SvgIcon,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  model: {
    prop: 'filesValue',
    event: 'filesChange',
  },
  props: {
    filesValue: {
      type: [Object, String, Array],
      required: false,
      default: null,
    },
    orderId: {
      type: [Number, String],
      required: true,
      default: null,
    },
    readonly: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    files: {
      get() {
        return this.filesValue
      },
      set(value) {
        this.$emit('filesChange', value)
      },
    },
  },
  created() {
    this.$emit('ready', true)
  },
  methods: {
    selectFile() {
      this.$refs.fileInput.click()
    },
    handleFile(event) {
      const selectedFile = event.target.files[0]
      if (selectedFile.size > 15000000) {
        this.toast({
          component: ToastificationContent,
          props: {
            title: 'Falha ao adicionar documento.',
            text:
              'O arquivo deve ter tamanho de até 15MB',
            icon: 'AlertTriangleIcon',
            variant: 'warning',
            timeout: false,
          },
        })
        return
      }
      const reader = new FileReader()
      reader.onload = () => {
        this.addFile(selectedFile.name, reader.result)
      }
      reader.readAsDataURL(selectedFile)
    },
    addFile(fileName, fileAttach) {
      const attach = {
        name: fileName,
        order: this.orderId,
        file: fileAttach,
      }

      syslic
        .order
        .file
        .addFile(attach)
        .then(response => {
          this.files.push(response.data)
          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Documento anexado com sucesso.',
              icon: 'EditIcon',
              variant: 'success',
            },
          })
        })
        .catch(() => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Falha ao anexar documento.',
              text: 'Não foi possível anexar o documento, por favor entre em contato com o administrador do sistema.',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              timeout: false,
            },
          })
        })
    },
    downloadFile(index) {
      syslic
        .order
        .file
        .fetchFile(this.files[index].id)
        .then(response => {
          const linkSource = `data:application/file;base64,${response.data.file}`
          const downloadLink = document.createElement('a')
          const fileName = this.files[index].name

          downloadLink.href = linkSource
          downloadLink.download = fileName
          downloadLink.click()
        })
        .catch(() => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Falha para realizar o download do documento.',
              text: 'Não foi possível realizar o download do documento, por favor entre em contato com o administrador do sistema.',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              timeout: false,
            },
          })
        })
    },
    showConfirmDeleteAttach(index) {
      this.$bvModal
        .msgBoxConfirm('Essa ação não poderá ser revertida, deseja realmente remover este anexo?', {
          id: 'confirmation_box',
          title: 'Deseja prosseguir?',
          size: 'sm',
          okVariant: 'primary',
          okTitle: 'Sim',
          cancelTitle: 'Não',
          cancelVariant: 'outline-primary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if (value) {
            this.deleteFile(index)
          }
        })
    },
    deleteFile(index) {
      syslic
        .order
        .file
        .deleteFile(this.files[index].id)
        .then(() => {
          this.files.splice(index, 1)
          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Documento removido com sucesso.',
              icon: 'EditIcon',
              variant: 'success',
            },
          })
        })
        .catch(() => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Falha ao remover documento.',
              text: 'Não foi possível remover o documento, por favor entre em contato com o administrador do sistema.',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              timeout: false,
            },
          })
        })
    },
  },
  setup() {
    const toast = useToast()

    return {
      toast,
      mdiFileDocumentOutline,
      mdiPlus,
      mdiTrashCanOutline,
    }
  },
}
</script>

<style lang="scss">
@import "@core/scss/base/core/colors/palette-variables.scss";

.orderAttachItem {
  margin-right: 0rem;
  margin-left: 0rem;
  border-bottom: 0.1rem solid #ebe9f1;
}

.orderAttachItem:hover {
  cursor: pointer;
  background-color: #efefef !important;
}

.orderAttachItem.itemOdd {
  background-color: #fafaf9;
}

.orderAttachTab {
  border-top: 1px solid #f6f6f6;
  min-height: 29.4rem;
}
</style>
