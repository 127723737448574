<template>
  <div id="orderEditPage">
    <div v-show="!isLoaded">
      <spin-loader />
    </div>
    <div v-show="isLoaded">
      <messager
        :module="'order'"
        :thread="order.id"
        :header="order.contract_set.bidding_set.client_set.name"
        @ready="syncLoad.ready('messager')"
      />
      <b-row class="justify-content-center mb-1">
        <b-col
          cols="12"
          md="11"
        >
          <div>
            <b-row>
              <b-col
                class="d-flex align-items-center justify-content-end mb-1 mb-md-0"
              >
                <b-dropdown
                  id="actions"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  right
                  class="dropdown-icon-wrapper"
                >
                  <template #button-content>
                    <feather-icon
                      icon="SettingsIcon"
                      size="16"
                      class="align-middle"
                    />
                  </template>

                  <b-dropdown-item
                    v-if="isViewMode"
                    id="bnt-edit"
                    :disabled="!hasPermissionToUpdate"
                    @click="enableEdition()"
                  >
                    <feather-icon icon="EditIcon" />
                    <span class="align-middle ml-50">Editar</span>
                  </b-dropdown-item>

                  <b-dropdown-item
                    :disabled="!hasPermissionToDelete"
                    @click="showConfirmOrCancelDelete(orderId)"
                  >
                    <svg-icon
                      type="mdi"
                      :path="mdiTrashCanOutline"
                    />
                    <span class="align-middle ml-50">Remover</span>
                  </b-dropdown-item>
                </b-dropdown>
              </b-col>
            </b-row>
          </div>
        </b-col>
      </b-row>
      <b-row class="justify-content-center">
        <b-col
          cols="12"
          md="11"
        >
          <validation-observer
            #default="{ handleSubmit, invalid }"
            ref="refFormObserver"
          >
            <b-form
              @submit.prevent="handleSubmit(onSubmit)"
            >
              <b-card
                class="mb-1"
                no-body
              >
                <b-card-title class="m-0">
                  <div class="py-2 px-2">
                    <b-row>
                      <b-col>
                        <span
                          class="d-inline orderEditTitle"
                        >
                          {{ order.contract_set.bidding_set.client_set.name }}
                        </span>
                        <b-button
                          id="btn-link-bidding"
                          type="button"
                          variant="outline-info"
                          class="mb-1 mb-sm-0 mr-0 p-0"
                          @click="goToBidding()"
                        >
                          <svg-icon
                            type="mdi"
                            :path="mdiLaunch"
                            size="18"
                          />
                        </b-button>
                      </b-col>
                    </b-row>
                    <b-row
                      class="mt-50"
                    >
                      <b-col
                        class="d-flex align-items-center"
                      >
                        <span
                          class="mr-1 clsOrderSubtitleLabel"
                        >
                          Nº Pregão:
                        </span>
                        <span
                          class="mr-50 clsOrderSubtitleContent"
                        >
                          {{ order.contract_set.bidding_set.trade_number }}
                        </span>
                        <div class="mr-1">
                          <span class="mr-50 clsOrderSubtitleLabel">CNPJ</span>
                          <span
                            v-show="order.contract_set.bidding_set.client_set.cnpj"
                            class="clsOrderSubtitleContent"
                          >
                            {{ order.contract_set.bidding_set.client_set.cnpj | VMask('##.###.###/####-##') }}
                          </span>
                          <span
                            v-show="!order.contract_set.bidding_set.client_set.cnpj"
                            class="clsOrderSubtitleContent"
                          >
                            ...
                          </span>
                        </div>
                        <b-button
                          id="btn-link-bidding"
                          type="button"
                          variant="outline-info"
                          class="d-flex align-items-center mb-1 mb-sm-0 mr-0 p-0"
                          @click="goToTrade()"
                        >
                          <svg-icon
                            type="mdi"
                            :path="mdiLink"
                            size="18"
                            style="color: #005C96"
                          />
                          {{ order.contract_set.bidding_set.link_trade | truncate(35, '...') }}
                        </b-button>
                      </b-col>
                    </b-row>
                  </div>
                  <hr class="m-0">
                </b-card-title>
                <b-card-body class="p-0">
                  <b-row class="px-2 pt-2">
                    <b-col
                      cols="12"
                      md="8"
                    >
                      <company-select
                        id="company"
                        v-model="order.company_set"
                        :readonly="true"
                        :required-field="true"
                        :truncate-field="17"
                        @ready="syncLoad.ready('company')"
                      />
                    </b-col>
                    <b-col
                      cols="12"
                      md="2"
                    >
                      <validation-provider
                        v-slot="{ errors }"
                        name="date_expiration_order"
                        :rules="{
                          required: true,
                        }"
                      >
                        <b-form-group
                          label="Validade do Pedido"
                          label-for="date_expiration_order"
                        >
                          <b-form-datepicker
                            id="id_date_expiration"
                            v-model="order.date_expiration"
                            :required="true"
                            locale="pt-BR"
                            :class="errors.length > 0 ? 'form-control is-invalid' : 'form-control'"
                            size="md"
                            :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                            label-no-date-selected="Nenhuma data foi selecionada"
                            selected-variant="primary"
                            :disabled="!hasPermissionToWrite || isViewMode"
                            placeholder="Selecione"
                            label-help="Use o teclado ou o mouse para navegar pelas datas do calendário"
                            reset-button
                            reset-button-variant="info"
                            label-reset-button="Limpar"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                    <b-col
                      cols="12"
                      md="2"
                    >
                      <owner-select
                        id="idOrderOwner"
                        v-model="order.owner_set"
                        :readonly="isViewMode"
                        :required-field="true"
                        label-field="Responsável pelo Pedido"
                        @ready="syncLoad.ready('owner')"
                      />
                    </b-col>
                  </b-row>
                  <b-row
                    class="px-2 pb-1"
                  >
                    <b-col
                      cols="12"
                      md="2"
                    >
                      <interest-select
                        id="idOrderInterest"
                        v-model="order.interest_set"
                        :required-field="true"
                        :readonly="isViewMode"
                        @ready="syncLoad.ready('interest')"
                      />
                    </b-col>
                    <b-col
                      cols="12"
                      md="2"
                      class="d-flex align-items-center pl-0"
                    >
                      <b-button
                        id="btn-address-order"
                        type="button"
                        variant="outline-primary"
                        class="p-50"
                        pill
                        @click="viewAddressFields()"
                      >
                        <svg-icon
                          v-if="!seeAddressFields"
                          type="mdi"
                          :path="mdiMapPlus"
                        />
                        <svg-icon
                          v-else
                          type="mdi"
                          :path="mdiMapMinus"
                        />
                      </b-button>
                    </b-col>
                  </b-row>
                  <div
                    v-show="seeAddressFields"
                  >
                    <hr
                      class="m-0"
                    >
                    <b-row
                      class="m-0"
                    >
                      <b-col
                        class="clsOrderAddressDelivery p-50"
                      >
                        <span class="ml-1">Endereço de Entrega</span>
                      </b-col>
                    </b-row>
                    <b-row
                      class="px-2"
                    >
                      <b-col class="p-0 my-1">
                        <address-edit-fields
                          id="address-fields-component"
                          class="px-1"
                          :address="order"
                          :is-view-mode="isViewMode"
                          :required-fields="[]"
                          :enabled-fields="enabledFieldsAddress"
                          :zip-code-field="'zip_code'"
                          :address-field="'address'"
                          :number-field="'number'"
                          :complement-field="'complement'"
                          :neighborhood-field="'neighborhood'"
                          :country-field="'country'"
                          :state-field="'state'"
                          :city-field="'city'"
                          @ready="syncLoad.ready('address')"
                        />
                      </b-col>
                    </b-row>
                  </div>
                </b-card-body>
              </b-card>
              <b-card no-body>
                <b-tabs
                  class="orderTab"
                  card
                >
                  <b-tab
                    class="p-0"
                    :active="activeTab === dataTab.commitment"
                    @click="selectedTab(dataTab.commitment)"
                  >
                    <template #title>
                      <b-card-title>
                        <svg-icon
                          class="mr-50 orderIconTitle"
                          type="mdi"
                          :path="mdiClipboardTextOutline"
                        />
                        <span class="d-inline">Empenhos</span>
                      </b-card-title>
                    </template>
                    <b-card-body
                      class="pt-0 pb-2"
                    >
                      <order-commitment
                        :order="order.id"
                        :contract="order.contract_set"
                        :readonly="isViewMode"
                        @ready="syncLoad.ready('commitment')"
                      />
                    </b-card-body>
                  </b-tab>
                  <b-tab
                    class="p-0"
                    :active="activeTab === dataTab.items"
                    @click="selectedTab(dataTab.items)"
                  >
                    <template #title>
                      <b-card-title>
                        <svg-icon
                          class="mr-50 orderIconTitle"
                          type="mdi"
                          :path="mdiPackageVariant"
                        />
                        <span class="d-inline">Itens</span>
                      </b-card-title>
                    </template>
                    <b-card-body
                      class="pt-0 pb-2 px-0"
                    >
                      <order-items
                        :order="orderId"
                        :readonly="isViewMode"
                        @ready="syncLoad.ready('items')"
                      />
                    </b-card-body>
                  </b-tab>
                  <b-tab
                    class="p-0"
                    :active="activeTab === dataTab.attachments"
                    @click="selectedTab(dataTab.attachments)"
                  >
                    <template #title>
                      <b-card-title>
                        <svg-icon
                          class="mr-50 orderIconTitle"
                          type="mdi"
                          :path="mdiPaperclip"
                        />
                        <span class="d-inline">Anexos</span>
                      </b-card-title>
                    </template>
                    <b-card-body
                      class="p-0"
                    >
                      <order-contracts
                        v-model="order.file_set"
                        :order-id="order.id"
                        :readonly="isViewMode"
                        @ready="syncLoad.ready('contracts')"
                      />
                    </b-card-body>
                  </b-tab>
                  <b-tab
                    class="p-0"
                    :active="activeTab === dataTab.delivery"
                    @click="selectedTab(dataTab.delivery)"
                  >
                    <template #title>
                      <b-card-title>
                        <svg-icon
                          class="mr-50 orderIconTitle"
                          type="mdi"
                          :path="mdiTruckOutline"
                        />
                        <span class="d-inline">Entregas</span>
                      </b-card-title>
                    </template>
                    <order-deliveries
                      :order-id="orderId"
                      :readonly="isViewMode"
                      :can-remove="hasPermissionToDelete"
                      @ready="syncLoad.ready('deliveries')"
                    />
                    <order-delivery-modal
                      ref="refOrderModal"
                      :order="order"
                      :readonly="isViewMode"
                    />
                  </b-tab>
                  <b-tab
                    class="p-0"
                    :active="activeTab === dataTab.assistance"
                    @click="selectedTab(dataTab.assistance)"
                  >
                    <template #title>
                      <b-card-title>
                        <svg-icon
                          class="mr-50 biddingIconTitle"
                          type="mdi"
                          :path="mdiHammerWrench"
                        />
                        <span class="d-inline">Assistências</span>
                      </b-card-title>
                    </template>
                    <b-row
                      v-if="activeTab === dataTab.assistance"
                    >
                      <b-col
                        class="pb-2"
                      >
                        <order-assistances
                          :order-id="orderId"
                          :readonly="isViewMode"
                        />
                      </b-col>
                    </b-row>
                  </b-tab>
                  <b-tab
                    class="p-0"
                    :active="activeTab === dataTab.history"
                    @click="selectedTab(dataTab.history)"
                  >
                    <template #title>
                      <b-card-title>
                        <svg-icon
                          class="mr-50 biddingIconTitle"
                          type="mdi"
                          size="20"
                          :path="mdiClipboardTextClockOutline"
                        />
                        <span class="d-inline">Histórico</span>
                      </b-card-title>
                    </template>
                    <b-row
                      v-if="activeTab === dataTab.history"
                    >
                      <b-col class="mt-3 pb-2">
                        <order-history
                          :order-id="orderId"
                        />
                      </b-col>
                    </b-row>
                  </b-tab>
                </b-tabs>
              </b-card>
              <b-row class="mb-2">
                <b-col
                  class="d-flex justify-content-end buttonsSaveCancel"
                >
                  <b-button
                    v-if="!isViewMode"
                    id="btn-save"
                    type="submit"
                    variant="primary"
                    class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                    :disable="invalid"
                  >
                    Salvar
                  </b-button>
                  <b-button
                    id="itemCancelButton"
                    variant="outline-primary"
                    class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                    :to=" { name: 'order' } "
                  >
                    Voltar
                  </b-button>
                </b-col>
              </b-row>
            </b-form>
          </validation-observer>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BCardTitle,
  BCardBody,
  BForm,
  BFormGroup,
  BButton,
  BDropdown,
  BDropdownItem,
  BFormDatepicker,
  BTab,
  BTabs,
} from 'bootstrap-vue'

import {
  mdiLink,
  mdiLaunch,
  mdiMapPlus,
  mdiMapMinus,
  mdiClipboardTextOutline,
  mdiHammerWrench,
  mdiPackageVariant,
  mdiPaperclip,
  mdiTruckOutline,
  mdiTrashCanOutline,
  mdiClipboardTextClockOutline,
} from '@mdi/js'

import {
  ValidationObserver,
  ValidationProvider,
} from 'vee-validate'

import SvgIcon from '@jamescoyle/vue-icon'
import Ripple from 'vue-ripple-directive'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import formValidation from '@core/comp-functions/forms/form-validation'
import CompanySelect from '@/components/company/CompanySelect.vue'
import InterestSelect from '@/components/order/InterestSelect.vue'
import OwnerSelect from '@/components/user/OwnerSelect.vue'
import AddressEditFields from '@/components/address/AddressEditFields.vue'
import syslic from '@/syslic'
import router from '@/router'
import SyncLoad from '@/components/utils/syncLoad'
import SpinLoader from '@/components/spinloader/SpinLoader.vue'
import Messager from '@/components/messager/Messager.vue'
import truncate from '@/components/utils/filters/truncate'
import OrderAssistances from './assistance/OrderAssistances.vue'
import OrderCommitment from './components/OrderCommitment.vue'
import OrderContracts from './components/OrderContracts.vue'
import OrderDeliveries from './delivery/OrderDeliveries.vue'
import OrderDeliveryModal from '@/views/order/delivery/OrderDeliveryModal.vue'
import OrderItems from './components/OrderItems.vue'
import OrderHistory from './history/OrderHistory.vue'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardTitle,
    BCardBody,
    BForm,
    BFormGroup,
    BButton,
    BTab,
    BTabs,
    ValidationObserver,
    ValidationProvider,
    BDropdown,
    BDropdownItem,
    BFormDatepicker,
    CompanySelect,
    InterestSelect,
    OwnerSelect,
    AddressEditFields,
    SpinLoader,
    SvgIcon,
    Messager,
    OrderAssistances,
    OrderCommitment,
    OrderContracts,
    OrderDeliveries,
    OrderDeliveryModal,
    OrderItems,
    OrderHistory,
  },
  directives: {
    Ripple,
  },
  filters: {
    truncate,
  },
  data() {
    const {
      refFormObserver,
      getValidationState,
    } = formValidation(this.order)

    let isViewMode = true

    if (router.currentRoute.path.match(/.*\/edit\/.*/)
        || router.currentRoute.path.match(/.*\/create/)) {
      isViewMode = false
    }

    const dataTab = {
      commitment: 1,
      items: 2,
      attachments: 3,
      delivery: 4,
      history: 5,
      assistance: 6,
    }

    let activeTab = dataTab.commitment

    if (router.currentRoute.path.match(/.*\/edit\/.*/)
        || router.currentRoute.path.match(/.*\/view/)) {
      activeTab = router.currentRoute.params.tab
    }

    const order = {
      address: '',
      bidding: '',
      contract_set: {
        bidding_set: {
          client_set: '',
        },
      },
      city: '',
      city_set: '',
      company: '',
      company_set: '',
      complement: '',
      country: '',
      country_set: '',
      date_expiration: '',
      has_delivery_done: false,
      id: '',
      interest: '',
      interest_set: '',
      neighborhood: '',
      nf_payed: '',
      number: '',
      owner: '',
      owner_set: '',
      price: '',
      state: '',
      state_set: '',
      zip_code: '',
      file_set: [],
    }

    if (router.currentRoute.path.match(/.*\/edit\/.*/)
        || router.currentRoute.path.match(/.*\/view\/.*/)) {
      order.id = router.currentRoute.params.id
    }

    const seeAddressFields = false
    const isLoaded = false

    const syncLoad = new SyncLoad()
    syncLoad.addTrigger('order')
    syncLoad.addTrigger('company')
    syncLoad.addTrigger('interest')
    syncLoad.addTrigger('owner')
    syncLoad.addTrigger('messager')
    syncLoad.addTrigger('commitment')
    syncLoad.addTrigger('contracts')
    syncLoad.addTrigger('items')
    syncLoad.addTrigger('deliveries')

    syncLoad.onReady(() => {
      this.isLoaded = true
    })

    const enabledFieldsAddress = [
      'zip_code',
      'address',
      'number',
      'complement',
      'neighborhood',
      'state',
      'city',
      'country',
    ]

    return {
      isViewMode,
      refFormObserver,
      getValidationState,
      text_error: '',
      dataTab,
      activeTab,
      order,
      orderId: null,
      seeAddressFields,
      isLoaded,
      syncLoad,
      enabledFieldsAddress,
    }
  },
  computed: {
    hasPermissionToUpdate() {
      const profile = syslic.authentication.getProfile()
      return profile.get_permissions_for_modules.order.can_edit
    },
    hasPermissionToDelete() {
      const profile = syslic.authentication.getProfile()
      return profile.get_permissions_for_modules.order.can_delete
    },
    hasPermissionToWrite() {
      const profile = syslic.authentication.getProfile()
      return profile.get_permissions_for_modules.order.can_write
    },
  },
  watch: {
    'order.company_set': function updateCompany(val) {
      if (val) {
        this.order.company = val.id
      } else {
        this.order.company = ''
      }
    },
    'order.interest_set': function updateInterest(val) {
      if (val) {
        this.order.interest = val.id
      } else {
        this.order.interest = ''
      }
    },
    'order.owner_set': function updateOwner(val) {
      if (val) {
        this.order.owner = val.id
      } else {
        this.order.owner = ''
      }
    },
  },
  created() {
    const idToUpdate = router.currentRoute.params.id
    this.orderId = router.currentRoute.params.id
    if (idToUpdate !== undefined) {
      syslic
        .order
        .fetchOrder(this.orderId)
        .then(response => {
          this.order = response.data
          this.syncLoad.ready('order')
        })
        .catch(err => {
          if (err.status === 404) {
            router.push({ name: 'error-404' })
          }
          this.syncLoad.ready('order')
        })
    } else {
      this.syncLoad.ready('order')
    }
  },
  methods: {
    updateOrder() {
      syslic
        .order
        .updateOrder(this.order.id, this.order)
        .then(() => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Pedido atualizado com sucesso.',
              icon: 'EditIcon',
              variant: 'success',
            },
          })
          this.disableEdition()
        })
        .catch(() => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Falha ao atualizar o Pedido.',
              text: 'Não foi possível atualizar o pedido, por favor entre em contato com o administrador do sistema.',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              timeout: false,
            },
          })
        })
    },
    onSubmit() {
      this.updateOrder()
    },
    deleteOrder(item) {
      if (this.order.has_delivery_done) {
        this.toast({
          component: ToastificationContent,
          props: {
            title: 'Falha ao remover pedido.',
            text: 'Não foi possível remover o pedido, pois ele já possui entrega ou empenho concluído.',
            icon: 'AlertTriangleIcon',
            variant: 'warning',
            timeout: false,
          },
        })
      } else {
        syslic
          .order
          .deleteOrder(item)
          .then(() => {
            this.toast({
              component: ToastificationContent,
              props: {
                title: 'Pedido removido com sucesso.',
                icon: 'EditIcon',
                variant: 'success',
              },
            })
            router.push({ name: 'order' })
          })
          .catch(() => {
            this.toast({
              component: ToastificationContent,
              props: {
                title: 'Falha ao remover pedido.',
                text: 'Não foi possível remover o pedido do servidor, por favor entre em contato com o administrador do sistema.',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
                timeout: false,
              },
            })
          })
      }
    },
    showConfirmOrCancelDelete(item) {
      this.$bvModal
        .msgBoxConfirm('Por favor, confirme que você quer remover o pedido. Está ação também irá remover os empenhos e entregas vinculados a este pedido.', {
          id: 'confirmation_box',
          title: 'Confirmar?',
          size: 'sm',
          okVariant: 'primary',
          okTitle: 'Sim',
          cancelTitle: 'Não',
          cancelVariant: 'outline-primary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if (value) {
            this.deleteOrder(item)
          }
        })
    },
    enableEdition() {
      router.push({
        name: 'order-edit',
        params: {
          tab: this.activeTab,
        },
      })
        .catch(() => {})
    },
    disableEdition() {
      router.push({
        name: 'order-detail',
        params: {
          id: this.orderId,
          tab: this.activeTab,
        },
      })
        .catch(() => {})
    },
    goToBidding() {
      const routeData = router.resolve({
        name: 'bidding-view',
        params: {
          id: this.order.contract_set.bidding_set.id,
        },
      })
      window.open(routeData.href, '_blank')
    },
    goToTrade() {
      const link = this.order.contract_set.bidding_set.link_trade
      if (link) window.open(link, '_blank')
    },
    viewAddressFields() {
      this.seeAddressFields = !this.seeAddressFields
    },
    selectedTab(value) {
      this.activeTab = value
    },
  },
  setup() {
    const toast = useToast()

    return {
      mdiLink,
      mdiLaunch,
      mdiMapPlus,
      mdiMapMinus,
      mdiClipboardTextOutline,
      mdiHammerWrench,
      mdiPackageVariant,
      mdiPaperclip,
      mdiTruckOutline,
      mdiTrashCanOutline,
      mdiClipboardTextClockOutline,
      toast,
    }
  },
}
</script>

<style lang="scss">
@import "@core/scss/base/core/colors/palette-variables.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";

#orderEditPage {
  transform: none !important;
}

.orderEditTitle {
  color: $primary;
  text-transform: uppercase;
}

.disabledEdition .input-group-text{
  background-color: #efefef;
}

input::placeholder {
  color: #b9b9c3 !important;
}

#btn-link-bidding {
  border: 0px !important;
}

#btn-address-order {
  border: 0px !important;
  margin-top: 4px;
}

#address-fields-component .card-body{
  padding: 0px;
}

.clsOrderSubtitleLabel {
  color: #005C96;
  font-size: 1rem;
  font-weight: bolder;
}
.clsOrderSubtitleContent {
  color: #494949;
  font-size: 1rem;
}

.clsOrderAddressDelivery {
  font-size: 1.18rem;
  font-weight: 500;
  color: $primary;
  background-color: #E3EDF3;
}

.orderIconTitle {
  width: 21px;
  height: 21px;
}

.orderTab .card-header {
  padding-top: 0;
  padding-bottom: 0;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}

.orderTab .nav-link {
  padding-left: 2rem;
  padding-right: 2rem;
}

.orderTab .nav-link h4 {
  color: #6e6b7b;
  font-size: 1rem;
}

.orderTab .nav-link.active {
  background-color: #e2edf3;
}

.orderTab .nav-link.active h4 {
  color: $primary;
}

.orderTab .nav-link:hover {
  box-shadow: 0 0 11px rgba(33,33,33,.2);
}
 .orderTab .card-body {
  padding-left: 1.0rem;
  padding-right: 1.0rem;
 }

 .orderTab .tab-content {
   margin-top: -1rem;
 }

 .buttonsSaveCancel{
  .btn{
    min-width: 107px;
  }
}
</style>
