<template>
  <validation-provider
    #default="{ errors }"
    name="assistance_product"
    :rules="{
      required: requiredField,
    }"
  >
    <b-form-group
      :label="label"
      label-for="assistance_product"
      :label-class="labelClass"
    >
      <v-select
        id="assistance_product"
        v-model="product"
        :class="errors.length > 0 ? 'error-select' : ''"
        :filter="fuseSearch"
        label="name"
        :options="dataOptions"
        :placeholder="'Produto da Assistência'"
        :disabled="readonly"
        :clearable="!readonly"
        @open="onCreate()"
      >
        <template #option="{ name }">
          <span>{{ name }}</span>
        </template>

        <template #selected-option="{ name }">
          <span>{{ name | truncate(35, '...') }}</span>
        </template>

        <template #no-options>
          Não há opções de Produto da Assistência.
        </template>
      </v-select>
      <small class="text-danger">{{ errors[0] }}</small>
    </b-form-group>
  </validation-provider>
</template>
<script>
import {
  BFormGroup,
} from 'bootstrap-vue'

import {
  ValidationProvider,
} from 'vee-validate'

import {
  required,
} from '@validations'

import vSelect from 'vue-select'
import Fuse from 'fuse.js'
import syslic from '@/syslic'
import truncate from '@/components/utils/filters/truncate'

export default {
  components: {
    BFormGroup,
    ValidationProvider,
    vSelect,
  },
  filters: {
    truncate,
  },
  model: {
    prop: 'productValue',
    event: 'productChange',
  },
  props: {
    productValue: {
      type: [Object, String],
      required: false,
      default: null,
    },
    readonly: {
      type: Boolean,
      required: false,
      default: false,
    },
    requiredField: {
      type: Boolean,
      required: false,
      default: false,
    },
    label: {
      type: [String, Number],
      required: false,
      default: 'Selecione o Produto',
    },
    labelClass: {
      type: [String, Array],
      required: false,
      default: '',
    },
    order: {
      type: [String, Number],
      required: true,
    },
  },
  data() {
    const dataOptions = []
    const newProductValue = this.productValue

    return {
      dataOptions,
      required,
      newProductValue,
    }
  },
  computed: {
    product: {
      get() {
        return this.newProductValue
      },
      set(value) {
        this.newProductValue = value
        this.$emit('productChange', this.newProductValue)
      },
    },
  },
  watch: {
    productValue(newVal) {
      this.newProductValue = newVal
    },
  },
  created() {
    this.onCreate()
  },
  methods: {
    onCreate() {
      syslic
        .order
        .product
        .getProductReport(this.order)
        .then(response => {
          this.dataOptions = response.data.products
          this.$emit('ready', true)
        })
        .catch(() => {
          this.$emit('ready', true)
        })
    },
    fuseSearch(options, search) {
      const fuse = new Fuse(options, {
        keys: ['name'],
        shouldSort: true,
      })
      return search.length
        ? fuse.search(search).map(({ item }) => item)
        : fuse.list
    },
  },
}
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
<style lang="scss" scoped>
.v-select {
  background-color: #fff;
}
</style>
