<template>
  <div>
    <div id="idItemListList">
      <b-row>
        <b-col
          cols="12"
        >
          <b-table
            ref="refItemTable"
            thead-class="itemTableHeader"
            tbody-tr-class="itemTableRow"
            primary-key="id"
            responsive
            show-empty
            striped
            hover
            empty-text="Não há itens cadastrados."
            :items="items"
            :fields="tableColumns"
            :sort-by.sync="sortBy"
            :sort-desc.sync="isSortDirDesc"
            :busy="isLoading"
          >
            <template #table-busy>
              <div class="text-center my-3">
                <b-spinner
                  variant="info"
                  class="align-middle mb-1"
                />
                <br>
                <strong class="pt-2">Carregando...</strong>
              </div>
            </template>
            <template #cell(field_lote)="field">
              <b-media vertical-align="center">
                <span
                  v-if="field.item.item_set.parent_set"
                  class="font-weight-bold d-block text-nowrap"
                >
                  {{ field.item.item_set.parent_set.name }}
                </span>
                <span
                  v-else
                  class="font-weight-bold d-block text-nowrap"
                >
                  -
                </span>
              </b-media>
            </template>

            <template #cell(field_type)="field">
              <b-media vertical-align="center">
                <span
                  class="font-weight-bold d-block text-nowrap"
                >
                  <svg-icon
                    v-if="field.item.item_set.type === 'unit'"
                    class="d-inline-block itemIconType ml-50"
                    type="mdi"
                    :path="mdiAlphaUBoxOutline"
                  />
                  <svg-icon
                    v-if="field.item.item_set.type === 'compound'"
                    class="d-inline-block itemIconType ml-50"
                    type="mdi"
                    :path="mdiAlphaCBox"
                  />
                </span>
              </b-media>
            </template>

            <template #cell(field_name)="field">
              <b-media vertical-align="center">
                <span
                  class="font-weight-bold d-block text-nowrap"
                >
                  {{ field.item.item_set.name }}
                </span>
              </b-media>
            </template>

            <template #cell(field_qtd)="field">
              <b-media vertical-align="center">
                <span
                  class="font-weight-bold d-block text-nowrap"
                >
                  {{ field.item.quantity.avaiable }}
                </span>
              </b-media>
            </template>

            <template #cell(field_commitment)="field">
              <b-media vertical-align="center">
                <span
                  class="font-weight-bold d-block text-nowrap"
                >
                  {{ field.item.quantity.commitment }}
                </span>
              </b-media>
            </template>

            <template #cell(field_delivery)="field">
              <b-media vertical-align="center">
                <span
                  class="font-weight-bold d-block text-nowrap"
                >
                  {{ field.item.quantity.delivery }}
                </span>
              </b-media>
            </template>

            <template #cell(field_cost)="field">
              <b-media vertical-align="center">
                <span
                  class="font-weight-bold d-block text-nowrap"
                >
                  R$ {{ field.item.item_set.price | formatCurrency() }}
                </span>
              </b-media>
            </template>

            <template #cell(field_cost_total)="field">
              <b-media vertical-align="center">
                <span
                  class="font-weight-bold d-block text-nowrap"
                >
                  R$ {{ (field.item.item_set.price * (field.item.quantity.avaiable + field.item.quantity.commitment)) | formatCurrency() }}
                </span>
              </b-media>
            </template>
          </b-table>
        </b-col>
      </b-row>
    </div>
  </div>
</template>
<script>
import {
  BRow,
  BCol,
  BTable,
  BMedia,
  VBTooltip,
  BSpinner,
} from 'bootstrap-vue'

import {
  mdiAlphaUBoxOutline,
  mdiAlphaCBox,
} from '@mdi/js'

import SvgIcon from '@jamescoyle/vue-icon'
import formValidation from '@core/comp-functions/forms/form-validation'
import formatCurrency from '@/components/utils/filters/formatCurrency'
import syslic from '@/syslic'
import router from '@/router'

export default {
  components: {
    BRow,
    BCol,
    BTable,
    BMedia,
    SvgIcon,
    BSpinner,
  },
  filters: {
    formatCurrency,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    order: {
      type: [String, Number],
      required: true,
    },
    readonly: {
      type: [Boolean],
      required: true,
    },
  },
  data() {
    const isLoading = true

    const {
      refFormObserver,
      getValidationState,
    } = formValidation(this.data)

    const sortBy = 'name'
    const isSortDirDesc = false
    const tableColumns = [
      {
        key: 'field_lote',
        label: 'lote',
        thStyle: { width: '10%' },
      },
      {
        key: 'field_type',
        label: 'Tipo',
        thStyle: { width: '5%' },
      },
      {
        key: 'field_name',
        label: 'Nome do produto',
        thStyle: { width: '22%' },
      },
      {
        key: 'field_qtd',
        label: 'QTD DISPONÍVEL',
        thStyle: { width: '13%' },
      },
      {
        key: 'field_commitment',
        label: 'Empenhado',
        thStyle: { width: '10%' },
      },
      {
        key: 'field_delivery',
        label: 'Entregue',
        thStyle: { width: '10%' },
      },
      {
        key: 'field_cost',
        label: 'Valor Unitário',
        thStyle: { width: '15%' },
      },
      {
        key: 'field_cost_total',
        label: 'Valor Total',
        thStyle: { width: '15%' },
      },
    ]

    const items = []

    return {
      refFormObserver,
      getValidationState,
      isLoading,
      sortBy,
      tableColumns,
      isSortDirDesc,
      items,
    }
  },
  created() {
    this.fetchItemsList()
  },
  methods: {
    fetchItemsList() {
      this.isLoading = true

      syslic
        .order
        .item
        .getItemReport(this.order)
        .then(response => {
          this.items = response.data.items
          this.isLoading = false
          this.$refs.refItemTable.refresh()
          this.$emit('ready', true)
        })
        .catch(err => {
          if (err.status === 404) {
            router.push({ name: 'error-404' })
          }
          this.isLoading = false
          this.$emit('ready', true)
        })
    },
  },
  setup() {
    return {
      mdiAlphaUBoxOutline,
      mdiAlphaCBox,
    }
  },
}
</script>
<style lang="scss">
@import "@core/scss/base/core/colors/palette-variables.scss";

#idItemListList .itemTableHeader th {
  color: $primary;
  font-weight: 600;
  font-size: 0.9rem;
  background-color: #E3EDF3;
}

#idItemListList .itemListHeader {
  background-color: #F6F6F9
}

#idItemListList .itemTableRow {
  cursor: default;
}
#idItemListList .headerTitle {
  color: $primary;
  font-weight: 600;
  font-size: 1.25rem;
}

#idItemListList .headerContent {
  color: #464646;
}

#idItemListList .itemIconType {
  color: $primary;
}
</style>
