<template>
  <div
    class="orderAssistancesTab"
  >
    <div
      v-if="isLoading"
      class="d-flex align-items-center justify-content-center"
    >
      <spin-loader
        id="assistanceLoader"
      />
    </div>
    <div v-if="!isLoading">
      <b-row>
        <b-col
          cols="12"
          md="12"
          sm="12"
        >
          <div
            class="assistancesValues"
          >
            <p>
              Total de Montagens e Instalações:
              <span>
                R$ {{ installationTypeValue | formatCurrency() }}
              </span>
            </p>
            <p>
              Total de Treinamentos:
              <span>
                R$ {{ trainingTypeValue | formatCurrency() }}
              </span>
            </p>
            <p>
              Total de Assistências:
              <span>
                R$ {{ assistanceTypeValue | formatCurrency() }}
              </span>
            </p>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          cols="12"
          md="12"
        >
          <b-table
            class="assistanceTableView"
            primary-key="number"
            show-empty
            responsive
            hover
            empty-text="Não há assistências para este pedido."
            :items="assistancesList"
            :fields="tableColumns"
            :busy="isLoading"
            tbody-tr-class="assistanceTableCustomHeight"
            thead-tr-class="d-none"
            @row-clicked="edit"
          >
            <template #table-busy>
              <div class="text-center my-3">
                <b-spinner
                  variant="info"
                  class="align-middle mb-1"
                />
                <br>
                <strong class="pt-2">Carregando ...</strong>
              </div>
            </template>

            <template #cell(product_set.name)="field">
              <span class="text-nowrap font-weight-bolder">
                Produto: {{ field.item.product_set.name | truncate(35, '...') }}
              </span>
            </template>

            <template #cell(type_set.name)="field">
              <span class="text-nowrap font-weight-bolder">
                Tipo de Serviço: {{ field.item.type_set.name }}
              </span>
            </template>

            <template #cell(date_scheduled)="field">
              <span
                v-if="field.item.date_scheduled"
                class="text-nowrap font-weight-bolder"
              >
                Data Programada: {{ moment(field.item.date_scheduled).format('DD/MM/YYYY') || '' }}
              </span>
              <span
                v-else
                class="text-nowrap font-weight-bolder"
              >
                Data Programada: -
              </span>
            </template>

            <template #cell(technician_name)="field">
              <span
                v-if="field.item.technician_name"
                class="text-nowrap font-weight-bolder"
              >
                Técnico: {{ field.item.technician_name }}
              </span>
              <span
                v-else
                class="text-nowrap font-weight-bolder"
              >
                Técnico: -
              </span>
            </template>

            <template #cell(status_set.name)="field">
              <div
                class="d-block text-nowrap font-weight-bold"
              >
                <b-badge
                  pill
                  class="text-capitalize sizeMin d-inline-flex align-items-center justify-content-center px-1"
                  :style="{'background-color': field.item.status_set.color }"
                >
                  {{ field.item.status_set.name }}
                </b-badge>
              </div>
            </template>

            <template
              v-if="!readonly"
              #cell(actions)="field"
            >
              <b-button
                class="btn-icon rounded-circle"
                variant="flat-info"
                size="lg"
                title="Excluir Assistência"
                @click="showConfirmOrCancelDelete(field.item)"
              >
                <svg-icon
                  type="mdi"
                  size="20"
                  :path="mdiTrashCanOutline"
                />
              </b-button>
            </template>
          </b-table>
        </b-col>
      </b-row>
      <b-row
        v-if="!(readonly)"
      >
        <b-col
          class="d-flex justify-content-end"
        >
          <assistance-modal
            ref="assistanceModal"
            :is-add="isAdd"
            :order-id="orderId"
            :assistance-value="assistanceValue"
            @save="handleSaveAssistance"
            @isAdd="isAddChange"
          />
          <div>
            <b-button
              id="addAssistanceAttachBtn"
              v-b-tooltip.hover
              class="btn-icon rounded-circle mr-1 mt-3"
              variant="primary"
              size="md"
              :title="'Adicionar Assistência'"
              @click="openAssistanceModal()"
            >
              <svg-icon
                type="mdi"
                :path="mdiPlus"
              />
            </b-button>
          </div>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BButton,
  BTable,
  BBadge,
  VBTooltip,
} from 'bootstrap-vue'

import {
  useToast,
} from 'vue-toastification/composition'

import {
  mdiPlus,
  mdiTrashCanOutline,
} from '@mdi/js'

import moment from 'moment'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import SvgIcon from '@jamescoyle/vue-icon'
import SpinLoader from '@/components/spinloader/SpinLoader.vue'
import syslic from '@/syslic'
import router from '@/router'
import SyncLoad from '@/components/utils/syncLoad'
import AssistanceModal from '@/views/order/assistance/AssistanceModal.vue'
import formatCurrency from '@/components/utils/filters/formatCurrency'
import truncate from '@/components/utils/filters/truncate'

export default {
  components: {
    BRow,
    BCol,
    BButton,
    BTable,
    BBadge,
    SpinLoader,
    AssistanceModal,
    SvgIcon,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  filters: {
    formatCurrency,
    truncate,
  },
  props: {
    orderId: {
      type: [Number, String],
      required: true,
    },
    readonly: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    const isFirstUpload = true

    const isAdd = true

    const isLoading = false

    const syncLoad = new SyncLoad()
    syncLoad.addTrigger('assistances')

    syncLoad.onReady(() => {
      this.isLoading = false

      if (this.isFirstUpload) {
        this.checkRouteAssistance()
        this.isFirstUpload = false
      }
    })

    const assistancesList = []

    const assistanceValue = null

    const tableColumns = [
      {
        key: 'product_set.name',
        label: 'Produto',
        visible: true,
        fixedColumn: false,
        sortable: true,
        thClass: 'text-nowrap',
        thStyle: { width: '20%' },
      },
      {
        key: 'type_set.name',
        label: 'Tipo de Serviço',
        visible: true,
        fixedColumn: false,
        sortable: true,
        thClass: 'text-nowrap',
        thStyle: { width: '20%' },
      },
      {
        key: 'date_scheduled',
        label: 'Data programada',
        visible: true,
        fixedColumn: false,
        sortable: true,
        thClass: 'text-nowrap',
        thStyle: { width: '15%' },
      },
      {
        key: 'technician_name',
        label: 'Técnico',
        visible: true,
        fixedColumn: false,
        sortable: true,
        thClass: 'text-nowrap',
        thStyle: { width: '15%' },
      },
      {
        key: 'status_set.name',
        label: 'Status',
        visible: true,
        fixedColumn: false,
        sortable: true,
        thClass: 'text-nowrap',
        thStyle: { width: '20%' },
      },
      {
        key: 'actions',
        label: '',
        sortable: false,
        visible: true,
        fixedColumn: true,
        tdClass: 'actionsTd',
        thStyle: { width: '10%' },
      },
    ]

    return {
      isFirstUpload,
      isAdd,
      tableColumns,
      isLoading,
      syncLoad,
      assistancesList,
      assistanceValue,
    }
  },
  computed: {
    installationTypeValue() {
      let value = 0
      const filteredAssistances = this.assistancesList.filter(assistance => assistance.type === 1)
      filteredAssistances.forEach(assistance => {
        if (parseFloat(assistance.payment_value) > 0.01) {
          value += parseFloat(assistance.payment_value)
        }
      })
      return value
    },
    assistanceTypeValue() {
      let value = 0
      const filteredAssistances = this.assistancesList.filter(assistance => assistance.type === 2)
      filteredAssistances.forEach(assistance => {
        if (parseFloat(assistance.payment_value) > 0.01) {
          value += parseFloat(assistance.payment_value)
        }
      })
      return value
    },
    trainingTypeValue() {
      let value = 0
      const filteredAssistances = this.assistancesList.filter(assistance => assistance.type === 3)
      filteredAssistances.forEach(assistance => {
        if (parseFloat(assistance.payment_value) > 0.01) {
          value += parseFloat(assistance.payment_value)
        }
      })
      return value
    },
  },
  created() {
    this.fetchData()
  },
  methods: {
    fetchData() {
      this.isLoading = true

      syslic
        .order
        .assistance
        .fetchAssistanceList({ order: this.orderId })
        .then(response => {
          this.assistancesList = response.data.results
        })
        .then(() => {
          this.syncLoad.ready('assistances')
        })
        .catch(() => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Erro ao carregar assistências',
              message: 'Não foi possível carregar as assistências do pedido, por favor entre em contato com o administrador do sistema.',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
          this.syncLoad.ready('assistances')
        })
    },
    createAssistance(val) {
      syslic
        .order
        .assistance
        .addAssistance(val)
        .then(() => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Assistência adicionada com sucesso',
              message: 'A assistência foi adicionada com sucesso.',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
          this.fetchData()
        })
        .catch(() => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Erro ao adicionar assistência',
              message: 'Não foi possível adicionar a assistência, por favor entre em contato com o administrador do sistema.',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    updateAssistance(val) {
      syslic
        .order
        .assistance
        .updateAssistanceBasic(val.id, val)
        .then(() => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Assistência atualizada com sucesso',
              message: 'A assistência foi atualizada com sucesso.',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
          this.fetchData()
        })
        .catch(() => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Erro ao atualizar assistência',
              message: 'Não foi possível atualizar a assistência, por favor entre em contato com o administrador do sistema.',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    deleteAssistance(val) {
      syslic
        .order
        .assistance
        .deleteAssistance(val.id)
        .then(() => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Assistência removida com sucesso.',
              icon: 'EditIcon',
              variant: 'success',
            },
          })
          this.assistancesList.splice(this.assistancesList.indexOf(val), 1)
          this.fetchData()
        })
        .catch(() => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Falha ao remover assistência.',
              text: 'Não foi possível remover a assistência do servidor, por favor entre em contato com o administrador do sistema.',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              timeout: false,
            },
          })
        })
    },
    checkRouteAssistance() {
      if (router.currentRoute.params.assistance) {
        const assistanceId = router.currentRoute.params.assistance
        syslic
          .order
          .assistance
          .fetchAssistanceBasic(assistanceId)
          .then(response => {
            this.assistanceValue = response.data
            setTimeout(() => {
              this.openAssistanceModal()
            }, 200)
            this.isFirstUpload = false
          })
          .catch(() => {
            this.toast({
              component: ToastificationContent,
              props: {
                title: 'Erro ao carregar assistência',
                message: 'Não foi possível carregar a assistência, por favor entre em contato com o administrador do sistema.',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          })
      }
    },
    handleSaveAssistance(val) {
      if (this.isAdd) {
        this.createAssistance(val)
      } else {
        this.updateAssistance(val)
      }
    },
    isAddChange(val) {
      this.isAdd = val
    },
    showConfirmOrCancelDelete(item) {
      this.$bvModal
        .msgBoxConfirm('Por favor, confirme que você quer remover a assistência.', {
          id: 'confirmation_box',
          title: 'Confirmar?',
          size: 'sm',
          okVariant: 'primary',
          okTitle: 'Sim',
          cancelTitle: 'Não',
          cancelVariant: 'outline-primary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if (value) {
            this.deleteAssistance(item)
          }
        })
    },
    edit(item) {
      this.assistanceValue = JSON.parse(JSON.stringify(item))
      setTimeout(() => {
        this.openAssistanceModal()
      }, 100)
    },
    openAssistanceModal() {
      this.assistanceValue = null
      this.$refs.assistanceModal.openModal()
    },
  },
  setup() {
    const toast = useToast()

    return {
      toast,
      moment,
      mdiPlus,
      mdiTrashCanOutline,
    }
  },
}
</script>

<style lang="scss">
@import "@core/scss/base/core/colors/palette-variables.scss";

.orderAssistancesTab {
  border-top: .1rem solid #f6f6f6;
  min-height: 10rem;
}

.assistancesValues {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 2rem;

  padding: 1.4rem 3rem;

  background-color: #E3EDF3;

  font-weight: 600;

  width: 100%;
  height: 100%;

  > p {
    margin: 0;
    color: $primary;

    > span {
      color: #464646;
      margin-left: .2rem;
    }
  }
}

.actionsTd {
  display: flex !important;
  align-items: center !important;
  justify-content: end !important;
  padding: .8rem .5rem !important;
}

#assistanceLoader {
  position: absolute;
  top: 25%;
}
</style>
