<template>
  <div
    class="d-flex align-items-center justify-content-end"
  >
    <div v-show="isLoading">
      <spin-loader />
    </div>
    <div v-show="!isLoading">
      <validation-observer
        v-slot="{ handleSubmit }"
        ref="refFormObserver"
      >
        <b-modal
          id="idModalDelivery"
          ref="modalDelivery"
          no-close-on-backdrop
          no-close-on-esc
          centered
          size="md"
          cancel-variant="outline-primary"
          footer-bg-variant="light"
          hide-footer
          @hidden="cancel()"
        >
          <template #modal-title>
            Criar Nova Entrega
          </template>
          <b-form
            class="mt-1"
          >
            <b-row
              class="mt-3 mb-1 p-2"
            >
              <b-col
                cols="12"
                md="12"
                class="d-flex justify-content-center"
              >
                <h4>Confirma a criação da entrega?</h4>
              </b-col>
            </b-row>
          </b-form>
          <div class="d-flex justify-content-center mb-3">
            <b-button
              id="btnConfirm"
              variant="primary"
              class="mr-1"
              @click="handleSubmit(save)"
            >
              Confirmar
            </b-button>
            <b-button
              variant="outline-primary"
              @click="cancel()"
            >
              Cancelar
            </b-button>
          </div>
        </b-modal>
      </validation-observer>
      <b-modal
        id="idModalCreatingDelivery"
        ref="modalCreatingDelivery"
        no-close-on-backdrop
        no-close-on-esc
        hide-header
        hide-footer
        centered
      >
        <div class="pt-5 pb-4">
          <div class="d-flex justify-content-center">
            <b-spinner
              id="spinnerImport"
              variant="primary"
              type="grow"
            />
          </div>
          <h4
            class="d-flex justify-content-center mt-2 pl-50"
          >
            Criando Entrega...
          </h4>
        </div>
      </b-modal>
    </div>
    <div
      v-if="!readonly"
      class="d-flex align-items-cente"
    >
      <b-button
        id="addDeliveryBtn"
        v-b-tooltip.hover
        v-b-modal.idModalDelivery
        class="btn-icon rounded-circle mr-2 my-2"
        variant="primary"
        size="md"
        :title="'Criar Entrega'"
        @click="deliveryModelInitial()"
      >
        <svg-icon
          type="mdi"
          :path="mdiPlus"
        />
      </b-button>
    </div>
  </div>
</template>
<script>
import {
  BRow,
  BCol,
  BForm,
  BButton,
  BSpinner,
  VBTooltip,
} from 'bootstrap-vue'

import {
  ValidationObserver,
} from 'vee-validate'

import {
  mdiPlus,
} from '@mdi/js'

import {
  useToast,
} from 'vue-toastification/composition'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import formValidation from '@core/comp-functions/forms/form-validation'
import SvgIcon from '@jamescoyle/vue-icon'
import SpinLoader from '@/components/spinloader/SpinLoader.vue'
import syslic from '@/syslic'
import SyncLoad from '@/components/utils/syncLoad'
import router from '@/router'

export default {
  components: {
    BRow,
    BCol,
    BForm,
    BButton,
    BSpinner,
    ValidationObserver,
    SvgIcon,
    SpinLoader,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    order: {
      type: [Object, String],
      required: false,
      default: null,
    },
    readonly: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    const {
      refFormObserver,
      getValidationState,
    } = formValidation(this.delivery)

    const isLoading = true

    const syncLoad = new SyncLoad()
    syncLoad.addTrigger('status')

    syncLoad.onReady(() => {
      this.isLoading = false
    })

    const statusData = null

    const delivery = {
      company: '',
      company_set: '',
      status: '',
      status_set: '',
      number: '',
      date_delivery: null,
      carrier: '',
      carrier_set: '',
      freight_cost: '',
      annotation: '',
      address: '',
      neighborhood: '',
      city: '',
      city_set: '',
      state: '',
      state_set: '',
      country: '',
      country_set: '',
      zip_code: '',
      items: [],
    }

    return {
      refFormObserver,
      getValidationState,
      syncLoad,
      isLoading,
      statusData,
      delivery,
    }
  },
  computed: {
    hasPermissionToWrite() {
      const profile = syslic.authentication.getProfile()
      return profile.get_permissions_for_modules.order.can_write
    },
  },
  created() {
    this.fetchStatus()
  },
  methods: {
    deliveryModelInitial() {
      this.delivery.company = this.order.company
      this.delivery.company_set = this.order.company_set
      this.delivery.client_set = this.order.contract_set.bidding_set.client_set
      this.delivery.order = this.order.id
      this.delivery.order_set = this.order
      this.delivery.date_delivery = null
      this.delivery.address = this.order.address
      this.delivery.number = this.order.number
      this.delivery.neighborhood = this.order.neighborhood
      this.delivery.city = this.order.city
      this.delivery.city_set = this.order.city_set
      this.delivery.state = this.order.state
      this.delivery.state_set = this.order.state_set
      this.delivery.country = this.order.country
      this.delivery.country_set = this.order.country_set
      this.delivery.zip_code = this.order.zip_code
      this.delivery.complement = this.order.complement
      this.delivery.status = ''
      this.delivery.status_set = ''
      this.delivery.carrier = ''
      this.delivery.carrier_set = ''
      this.delivery.freight_cost = ''
      this.delivery.annotation = ''
    },
    fetchStatus() {
      syslic
        .order
        .delivery
        .status
        .fetchAllDeliveryStatus({
          initial: true,
        })
        .then(response => {
          if (response.data.results.length > 0) {
            this.statusData = response.data.results[0].id
            this.syncLoad.ready('status')
          } else {
            this.statusData = null
            this.syncLoad.ready('status')
          }
        })
        .catch(() => {
          this.syncLoad.ready('status')
        })
    },
    save() {
      if (this.statusData) {
        this.delivery.status = this.statusData
        this.addDelivery()
      } else {
        this.showMissingInitialStatusToast()
      }
    },
    addDelivery() {
      this.$refs.modalDelivery.hide()
      this.$refs.modalCreatingDelivery.show()
      syslic
        .order
        .delivery
        .addDelivery(this.delivery)
        .then(response => {
          this.$refs.modalCreatingDelivery.hide()
          this.delivery = response.data
          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Entrega adicionada com sucesso.',
              icon: 'EditIcon',
              variant: 'success',
            },
          })
          router.push({
            name: 'delivery-edit',
            params: {
              id: this.order.id,
              idDelivery: this.delivery.id,
              fromOrderPage: true,
            },
          })
        })
        .catch(() => {
          this.$refs.modalCreatingDelivery.hide()
          const textError = 'Não foi possível adicionar a entrega, por favor entre em contato com o administrador do sistema.'

          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Falha ao adicionar entrega.',
              text: textError,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              timeout: false,
            },
          })
        })
    },
    cancel() {
      this.$refs.modalDelivery.hide()
      this.deliveryModelInitial()
    },
    showMissingInitialStatusToast() {
      this.toast({
        component: ToastificationContent,
        props: {
          title: 'Falha ao criar entrega.',
          text: 'Não existe status inicial para o status da entrega, para incluir acesse as configurações.',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      },
      { timeout: 5000 })
    },
  },
  setup() {
    const toast = useToast()

    const cnpjDelimiter = {
      delimiters: ['.', '.', '/', '-'],
      blocks: [2, 3, 3, 4, 2],
      uppercase: true,
    }

    return {
      toast,
      cnpjDelimiter,
      mdiPlus,
    }
  },
}
</script>
<style lang="scss">
@import "@core/scss/base/core/colors/palette-variables.scss";

 #idModalDelivery {
  .modal-body{
    padding: 0 !important;
  }
  .modal-header .modal-title{
    font-size: 1.2em;
    color: $primary !important;
    font-weight: bolder;
  }
 }

 #idModalDelivery .modal-footer {
  display: flex !important;
  flex-direction: row-reverse !important;
  justify-content: end !important;
  background-color: #fff !important;

  .btn{
    min-width: 107px;
  }
}

[dir] .modal-footer {
  border: 0px !important;
  background-color: #fff !important;
}

#spinnerImport {
  width: 5rem;
  height: 5rem;
}

#idModalCreatingDelivery {
  #idModalImportingBid___BV_modal_content_{
    border-radius:20px !important;
  }
}
</style>
