<template>
  <div>
    <div>
      <validation-observer
        v-slot="{ handleSubmit }"
        ref="refFormObserver"
      >
        <b-modal
          id="idModalAssistance"
          ref="modalAssistance"
          no-close-on-backdrop
          no-close-on-esc
          centered
          size="lg"
          cancel-variant="outline-primary"
          hide-footer
          @hidden="cancel()"
        >
          <template #modal-title>
            Assistência Técnica / Montagem / Treinamento
          </template>
          <b-form
            class="mt-0"
            @submit.prevent="handleSubmit(save)"
          >
            <h6
              class="my-0 pl-2 py-75 titleRow"
            >
              Serviço
            </h6>
            <b-row
              class="px-2 pt-1"
            >
              <b-col
                cols="12"
                md="5"
              >
                <assistance-type-select
                  v-model="assistanceData.type_set"
                  :label="'Selecione o Tipo do Serviço'"
                  :required-field="true"
                  :label-class="'formLabel'"
                />
              </b-col>
              <b-col
                cols="12"
                md="7"
              >
                <assistance-product-select
                  v-model="assistanceData.product_set"
                  :required-field="true"
                  :order="orderId"
                  :label-class="'formLabel'"
                />
              </b-col>
            </b-row>
            <b-row
              class="px-2"
            >
              <b-col
                cols="12"
                md="5"
              >
                <b-form-group
                  label="Fornecedor"
                  label-for="product_supplier"
                  :label-class="'formLabel'"
                >
                  <template v-if="assistanceData.product_set.supplier_set">
                    <b-form-input
                      id="product_supplier"
                      v-model="assistanceData.product_set.supplier_set.name"
                      :placeholder="assistanceData.product_set.supplier_set.name ? '' : 'Fornecedor'"
                      name="product_supplier"
                      :disabled="true"
                    />
                  </template>
                  <template v-else>
                    <b-form-input
                      :disabled="true"
                      :placeholder="'Fornecedor'"
                    />
                  </template>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="2"
              >
                <b-form-group
                  label="Marca"
                  label-for="product_brand"
                  :label-class="'formLabel'"
                >
                  <template v-if="assistanceData.product_set.brand">
                    <b-form-input
                      id="product_brand"
                      v-model="assistanceData.product_set.brand"
                      :placeholder="assistanceData.product_set.brand ? '' : 'Marca'"
                      name="product_brand"
                      :disabled="true"
                    />
                  </template>
                  <template v-else>
                    <b-form-input
                      :disabled="true"
                      :placeholder="'Marca'"
                    />
                  </template>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="2"
              >
                <b-form-group
                  label="Modelo"
                  label-for="product_model"
                  :label-class="'formLabel'"
                >
                  <template v-if="assistanceData.product_set.model">
                    <b-form-input
                      id="product_model"
                      v-model="assistanceData.product_set.model"
                      :placeholder="assistanceData.product_set.model ? '' : 'Modelo'"
                      name="product_model"
                      :readonly="true"
                    />
                  </template>
                  <template v-else>
                    <b-form-input
                      :disabled="true"
                      :placeholder="'Modelo'"
                    />
                  </template>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="3"
              >
                <b-form-group
                  label="Contato do Órgão"
                  label-for="client_phone_number"
                  :label-class="'formLabel'"
                >
                  <template v-if="order.contract_set.bidding_set.client_set.phone_number">
                    <b-form-input
                      id="client_phone_number"
                      v-model="order.contract_set.bidding_set.client_set.phone_number"
                      v-mask="getMask('client_phone_number')"
                      :placeholder="order.contract_set.bidding_set.client_set.phone_number ? '' : '(99) 99999-9999'"
                      name="client_phone_number"
                      :readonly="true"
                    />
                  </template>
                  <template v-else>
                    <b-form-input
                      id="client_phone_number"
                      v-model="assistanceData.organ_phone"
                      v-mask="getMask('client_phone_number')"
                      :placeholder="'(99) 99999-9999'"
                    />
                  </template>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row
              class="px-2"
            >
              <b-col
                cols="12"
                md="12"
              >
                <b-form-group
                  label="Observações e Descrição"
                  label-for="comments"
                  :label-class="'formLabel'"
                >
                  <b-form-textarea
                    id="comments"
                    v-model="assistanceData.comments"
                    :placeholder="assistanceData.comments ? '' : 'Observações e Descrição'"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <h6
              class="my-0 pl-2 py-75 titleRow"
            >
              Dados do Executor
            </h6>
            <b-row
              class="px-2 pt-1"
            >
              <b-col
                cols="12"
                md="6"
              >
                <b-form-group
                  label="Nome do Técnico"
                  label-for="technician_name"
                  :label-class="'formLabel'"
                >
                  <b-form-input
                    id="technician_name"
                    v-model="assistanceData.technician_name"
                    :placeholder="assistanceData.technician_name ? '' : 'Nome do Técnico'"
                    name="technician_name"
                  />
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
              >
                <validation-provider
                  #default="validationContext"
                  name="phone_number"
                >
                  <b-form-group
                    label="Contato do Técnico"
                    label-for="technician_phone"
                    :label-class="'formLabel'"
                  >
                    <b-form-input
                      id="technician_phone"
                      v-model="assistanceData.technician_phone"
                      v-mask="getMask('technician_phone')"
                      :state="readonly ? null : getValidationState(validationContext)"
                      :placeholder="assistanceData.technician_phone ? '' : '(99) 99999-9999'"
                    />
                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col
                cols="12"
                md="4"
              >
                <b-form-group
                  label="Data Programada da Assistência"
                  label-for="date_scheduled"
                  :label-class="'formLabel'"
                >
                  <b-form-datepicker
                    id="date_scheduled"
                    v-model="assistanceData.date_scheduled"
                    locale="pt-BR"
                    size="md"
                    :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                    label-no-date-selected="Nenhuma data foi selecionada"
                    selected-variant="primary"
                    placeholder="Selecione"
                    label-help="Use o teclado ou o mouse para navegar pelas datas do calendário"
                    reset-button
                    reset-button-variant="info"
                    label-reset-button="Limpar"
                  />
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="4"
              >
                <cleave-customized
                  v-model="assistanceData.payment_value"
                  :option="options.price"
                  :places="2"
                  :label="'Valor do Pagamento'"
                  :label-class="'formLabel'"
                />
              </b-col>
              <b-col
                cols="12"
                md="4"
              >
                <assistance-status-select
                  v-model="assistanceData.status_set"
                  :required-field="true"
                  :label-class="'formLabel'"
                />
              </b-col>
            </b-row>
            <b-row
              class="px-2 pt-1 pb-2"
            >
              <b-col
                cols="12"
                md="12"
                class="d-flex justify-content-end"
              >
                <b-button
                  class="d-flex align-items-center justify-content-center  px-3 mr-1"
                  variant="primary"
                  type="submit"
                >
                  Salvar
                </b-button>
                <b-button
                  class="d-flex align-items-center justify-content-center px-3 "
                  variant="outline-primary"
                  @click="cancel()"
                >
                  Voltar
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </b-modal>
      </validation-observer>
    </div>
  </div>
</template>
<script>
import {
  BRow,
  BCol,
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BFormInvalidFeedback,
  BFormDatepicker,
  VBTooltip,
} from 'bootstrap-vue'

import {
  ValidationProvider,
  ValidationObserver,
} from 'vee-validate'

import {
  mdiPlus,
} from '@mdi/js'

import {
  useToast,
} from 'vue-toastification/composition'

import formValidation from '@core/comp-functions/forms/form-validation'
import CleaveCustomized from '@/components/utils/CleaveCustomized.vue'
import ToastificationContent from '@/@core/components/toastification/ToastificationContent.vue'
import syslic from '@/syslic'
import AssistanceStatusSelect from '@/components/order/AssistanceStatusSelect.vue'
import AssistanceTypeSelect from '@/components/order/AssistanceTypeSelect.vue'
import AssistanceProductSelect from '@/components/order/AssistanceProductSelect.vue'
import SyncLoad from '@/components/utils/syncLoad'

export default {
  components: {
    BRow,
    BCol,
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BFormInvalidFeedback,
    BFormDatepicker,
    CleaveCustomized,
    AssistanceStatusSelect,
    AssistanceTypeSelect,
    AssistanceProductSelect,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    assistanceValue: {
      type: [Object, String],
      required: false,
      default: null,
    },
    orderId: {
      type: [Number, String],
      required: true,
      default: null,
    },
    readonly: {
      type: Boolean,
      required: false,
      default: false,
    },
    isAdd: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  data() {
    const order = {
      contract_set: {
        bidding_set: {
          client_set: {
            phone_number: '',
          },
        },
      },
    }

    const assistanceEmpty = {
      id: '',
      order: '',
      type: '',
      type_set: '',
      status: '',
      product: '',
      product_set: '',
      technician_name: '',
      technician_phone: '',
      organ_phone: '',
      comments: '',
      date_scheduled: null,
      payment_value: '',
    }

    const assistanceData = JSON.parse(JSON.stringify(assistanceEmpty))

    const options = {
      price: {
        numeral: true,
        numeralDecimalScale: 2,
        numeralDecimalMark: ',',
        delimiter: '.',
        numeralPositiveOnly: true,
      },
    }

    const {
      refFormObserver,
      getValidationState,
    } = formValidation(this.assistanceData)

    const syncLoad = new SyncLoad()

    return {
      order,
      options,
      assistanceEmpty,
      assistanceData,
      refFormObserver,
      getValidationState,
      syncLoad,
    }
  },
  computed: {
    hasPermissionToWrite() {
      const profile = syslic.authentication.getProfile()
      return profile.get_permissions_for_modules.order.can_write
    },
  },
  watch: {
  },
  created() {
    syslic
      .order
      .assistance
      .fetchOrder(this.orderId)
      .then(response => {
        this.order = response.data
      })
      .catch(() => {
        this.toast({
          component: ToastificationContent,
          props: {
            title: 'Falha ao ler dados do pedido.',
            text: 'Não foi possível ler os dados do pedido, por favor entre em contato com o administrador do sistema.',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            timeout: false,
          },
        })
      })
  },
  methods: {
    openModal() {
      if (this.assistanceValue !== null) {
        this.assistanceData = JSON.parse(JSON.stringify(this.assistanceValue))
        this.$emit('isAdd', false)
      } else {
        this.$emit('isAdd', true)
      }
      this.$refs.modalAssistance.show()
    },
    save() {
      const data = {
        id: this.assistanceData.id ? this.assistanceData.id : '',
        order: this.orderId,
        product: this.assistanceData.product_set.id,
        status: this.assistanceData.status_set.id,
        type: this.assistanceData.type_set.id,
        comments: this.assistanceData.comments ? this.assistanceData.comments : '',
        date_scheduled: this.assistanceData.date_scheduled ? this.assistanceData.date_scheduled : null,
        payment_value: this.assistanceData.payment_value ? this.assistanceData.payment_value : '0.00',
        organ_phone: '',
        technician_name: this.assistanceData.technician_name ? this.assistanceData.technician_name : '',
        technician_phone: this.assistanceData.technician_phone ? this.assistanceData.technician_phone : '',
      }
      if (this.order.contract_set.bidding_set.client_set.phone_number) {
        data.organ_phone = this.order.contract_set.bidding_set.client_set.phone_number
      } else if (this.assistanceData.organ_phone) {
        data.organ_phone = this.assistanceData.organ_phone
      }

      this.$refs.modalAssistance.hide()
      this.$emit('save', data)
      this.clearData()
    },
    cancel() {
      this.$refs.modalAssistance.hide()
      this.clearData()
      this.$emit('isAdd', true)
    },
    getMask(field) {
      let mask = '(##) ####-####'
      if (this.assistanceData[field]) {
        if (this.assistanceData[field].length > 14) {
          mask = '(##) #####-####'
        }
      }

      return mask
    },
    clearData() {
      setTimeout(() => {
        this.assistanceData = JSON.parse(JSON.stringify(this.assistanceEmpty))
      }, 200)
    },
  },
  setup() {
    const toast = useToast()
    return {
      toast,
      mdiPlus,
    }
  },
}
</script>
<style lang="scss">
@import "@core/scss/base/core/colors/palette-variables.scss";

#idModalAssistance {
  .modal-body{
    padding: 0 !important;
  }
  .modal-header .modal-title{
    font-size: 1.2em;
  }

  .modal-lg, .modal-xl {
    max-width: 1327px !important;
  }
 }

 #idModalAssistance .modal-footer {
  display: flex !important;
  flex-direction: row-reverse !important;
  justify-content: end !important;
  background-color: #fff !important;

  .btn{
    min-width: 107px;
  }
}

.titleRow {
  background-color: #E3EDF3;
  color: #005C96;
  font-weight: bolder;
}

.formLabel {
  font-weight: bolder;
}
</style>
