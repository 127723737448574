<template>
  <div>
    <div v-show="isLoading">
      <spin-loader />
    </div>
    <div>
      <history-list
        :history-list="historyList"
        :labels="labelList"
      />
    </div>
  </div>
</template>
<script>
import {
  mdiGavel,
  mdiClipboardEditOutline,
  mdiClipboardRemoveOutline,
  mdiPaperclipPlus,
  mdiPaperclipRemove,
  mdiClipboardTextMultipleOutline,
} from '@mdi/js'

import {
  useToast,
} from 'vue-toastification/composition'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import syslic from '@/syslic'
import SyncLoad from '@/components/utils/syncLoad'
import SpinLoader from '@/components/spinloader/SpinLoader.vue'
import HistoryList from '@/components/history/HistoryList.vue'

export default {
  components: {
    SpinLoader,
    HistoryList,
  },
  props: {
    orderId: {
      type: [Number, String],
      required: true,
    },
  },
  data() {
    const isLoading = true
    const syncLoad = new SyncLoad()
    syncLoad.addTrigger('history')

    syncLoad.onReady(() => {
      this.isLoading = false
    })

    const historyList = []

    const labelList = [
      {
        type: '+',
        model: 'Order',
        label: 'Cadastro de Pedido',
        icon: mdiClipboardTextMultipleOutline,
      },
      {
        type: '~',
        model: 'Order',
        label: 'Edição do Pedido',
        icon: mdiClipboardEditOutline,
      },
      {
        type: '-',
        model: 'Order',
        label: 'Exclusão de Pedido',
        icon: mdiClipboardRemoveOutline,
      },
      {
        type: '+',
        model: 'OrderFile',
        label: 'Inclusão de Anexo',
        icon: mdiPaperclipPlus,
      },
      {
        type: '-',
        model: 'OrderFile',
        label: 'Exclusão de Anexo',
        icon: mdiPaperclipRemove,
      },
    ]

    return {
      isLoading,
      syncLoad,
      historyList,
      labelList,
    }
  },
  computed: {

  },
  created() {
    this.fetchHistorics()
  },
  methods: {
    fetchHistorics() {
      syslic
        .order
        .history
        .fetchHistory(this.orderId)
        .then(response => {
          this.historyList = response.data.events
          this.syncLoad.ready('history')
        })
        .catch(() => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Não foi possível carregar o histórico deste pedido.',
              text: 'Não foi possível carregar o histórico, por favor entre em contato com o administrador do sistema.',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              timeout: false,
            },
          })
          this.syncLoad.ready('history')
        })
    },
    isEmpty(obj) {
      return Object.keys(obj).length === 0
    },
  },
  setup() {
    const toast = useToast()

    return {
      toast,
      mdiGavel,
      mdiClipboardEditOutline,
      mdiClipboardRemoveOutline,
      mdiPaperclipPlus,
      mdiPaperclipRemove,
      mdiClipboardTextMultipleOutline,
    }
  },
}
</script>

<style lang="sass">

</style>
