<template>
  <div>
    <div class="pb-5">
      <div v-if="commitments.length > 0">
        <div
          v-for="(item, index) in commitments"
          :key="index"
        >
          <b-row
            :class="['p-1', 'commitmentListRow', index % 2 === 0 ? 'isEven' : '']"
          >
            <b-col
              cols="12"
              md="1"
              class="commitmentCardItemBlock commitmentCardItemSection px-50"
            >
              <div>
                <span
                  class="commitmentCardItemSectionTitle"
                >
                  Situação
                </span>
                <b-media
                  vertical-align="center"
                  class="commitmentCardItemSectionContent"
                >
                  <b-row>
                    <b-col>
                      <span
                        v-b-tooltip.hover
                        :title="setTooltipTitleAudit(item.situation)"
                        :class="getAuditSvgClass(item.situation)"
                      >
                        <svg-icon
                          type="mdi"
                          size="18"
                          :path="mdiFileSearch"
                        />
                      </span>
                      <span
                        v-b-tooltip.hover
                        :title="setTooltipTitleDone(item.situation)"
                        class="ml-50"
                        :class="item.situation === 'done' ? 'commitmentIsDone' : ''"
                      >
                        <svg-icon
                          type="mdi"
                          size="18"
                          :path="mdiCheckDecagram"
                        />
                      </span>
                    </b-col>
                  </b-row>
                </b-media>
              </div>
            </b-col>
            <b-col
              cols="12"
              md="2"
              class="commitmentCardItemBlock commitmentCardItemSection px-50"
              @click="editCommitment(item)"
            >
              <div>
                <span
                  class="commitmentCardItemSectionTitle"
                >
                  Empresa
                </span>
                <b-media
                  class="commitmentCardItemSectionContent"
                >
                  <b-row>
                    <b-col>
                      <h5
                        v-if="item.company_set"
                        class="mb-0 d-flex pl-50"
                      >
                        {{ item.company_set.corporate_name }}
                      </h5>
                      <h5 v-else>
                        -
                      </h5>
                    </b-col>
                  </b-row>
                </b-media>
              </div>
            </b-col>
            <b-col
              cols="12"
              md="2"
              class="commitmentCardItemBlock commitmentCardItemSection px-50"
              @click="editCommitment(item)"
            >
              <div>
                <span
                  class="commitmentCardItemSectionTitle"
                >
                  Nº Empenho
                </span>
                <b-media
                  class="commitmentCardItemSectionContent"
                >
                  <b-row>
                    <b-col>
                      <h5
                        v-if="item.number"
                        class="mb-0 d-flex pl-50"
                      >
                        {{ item.number }}
                      </h5>
                      <h5 v-else>
                        -
                      </h5>
                    </b-col>
                  </b-row>
                </b-media>
              </div>
            </b-col>
            <b-col
              cols="12"
              md="2"
              class="commitmentCardItemBlock commitmentCardItemSection px-50"
              @click="editCommitment(item)"
            >
              <div>
                <span
                  class="commitmentCardItemSectionTitle"
                >
                  Prazo de Entrega
                </span>
                <b-media
                  class="commitmentCardItemSectionContent"
                >
                  <b-row>
                    <b-col>
                      <h5
                        v-if="item.date_delivery"
                        class="mb-0 d-flex pl-50"
                      >
                        {{ moment(item.date_delivery).format('DD/MM/YYYY') }}
                      </h5>
                      <h5 v-else>
                        -
                      </h5>
                    </b-col>
                  </b-row>
                </b-media>
              </div>
            </b-col>
            <b-col
              cols="12"
              md="2"
              class="commitmentCardItemBlock commitmentCardItemSection px-50"
              @click="editCommitment(item)"
            >
              <div>
                <span
                  class="commitmentCardItemSectionTitle"
                >
                  Recebimento do Empenho
                </span>
                <b-media
                  class="commitmentCardItemSectionContent"
                >
                  <b-row>
                    <b-col>
                      <h5
                        v-if="item.date_expiration"
                        class="mb-0 d-flex pl-50"
                      >
                        {{ moment(item.date_expiration).format('DD/MM/YYYY') }}
                      </h5>
                      <h5 v-else>
                        -
                      </h5>
                    </b-col>
                  </b-row>
                </b-media>
              </div>
            </b-col>
            <b-col
              cols="12"
              md="3"
              class="d-flex justify-content-between commitmentCardItemBlock commitmentCardItemSection px-50"
            >
              <div
                @click="editCommitment(item)"
              >
                <span
                  class="commitmentCardItemSectionTitle"
                >
                  Status do Empenho
                </span>
                <div
                  class="d-inline"
                >
                  <h5
                    v-if="item.status_set"
                    class="mb-0 pl-50"
                  >
                    <b-badge
                      pill
                      class="statusSize"
                      :style="{'background-color': item.status_set.color}"
                    >
                      {{ item.status_set.name }}
                    </b-badge>
                  </h5>
                  <h5 v-else>
                    -
                  </h5>
                </div>
              </div>
              <b-button
                v-if="!readonly && hasPermissionToDelete"
                class="btn-icon rounded-circle"
                variant="flat-info"
                size="lg"
                title="Excluir Empenho"
                @click="showConfirmOrCancelDelete(item.id)"
              >
                <svg-icon
                  type="mdi"
                  size="20"
                  :path="mdiTrashCanOutline"
                />
              </b-button>
            </b-col>
          </b-row>
        </div>
      </div>
      <b-row v-else>
        <b-col
          class="d-flex align-items-center justify-content-center mt-2"
        >
          <span>Não existe empenho cadastrado neste pedido.</span>
        </b-col>
      </b-row>
    </div>
    <b-row v-if="!readonly">
      <b-col
        class="d-flex justify-content-end btnAddCommitment"
      >
        <b-button
          id="btnAddCommitment"
          class="btn-icon rounded-circle mb-1 mr-50"
          variant="primary"
          size="md"
          :title="'Adicionar Empenho'"
          @click="addCommitment()"
        >
          <svg-icon
            type="mdi"
            :path="mdiPlus"
          />
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BMedia,
  BButton,
  BBadge,
  VBTooltip,
} from 'bootstrap-vue'

import {
  mdiPlus,
  mdiTrashCanOutline,
  mdiFileSearch,
  mdiCheckDecagram,
} from '@mdi/js'

import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import moment from 'moment'
import SvgIcon from '@jamescoyle/vue-icon'
import router from '@/router'
import syslic from '@/syslic'

export default {
  components: {
    BRow,
    BCol,
    BMedia,
    BButton,
    BBadge,
    SvgIcon,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    order: {
      type: [String, Number],
      required: true,
    },
    readonly: {
      type: [Boolean],
      required: true,
    },
    contract: {
      type: Object,
      default: null,
    },
  },
  data() {
    const orderId = router.currentRoute.params.id

    const commitments = []

    return {
      orderId,
      commitments,
    }
  },
  computed: {
    hasPermissionToUpdate() {
      const profile = syslic.authentication.getProfile()
      return profile.get_permissions_for_modules.order.can_edit
    },
    hasPermissionToDelete() {
      const profile = syslic.authentication.getProfile()
      return profile.get_permissions_for_modules.order.can_delete
    },
    hasPermissionToWrite() {
      const profile = syslic.authentication.getProfile()
      return profile.get_permissions_for_modules.order.can_write
    },
  },
  created() {
    this.fetchData()
  },
  methods: {
    fetchData() {
      const params = {
        order: this.orderId,
      }
      syslic
        .order
        .commitment
        .fetchCommitmentList(params)
        .then(response => {
          this.commitments = response.data.results
          this.$emit('ready', true)
        })
        .catch(err => {
          if (err.status === 404) {
            router.push({ name: 'error-404' })
          }
          this.$emit('ready', true)
        })
    },
    addCommitment() {
      if (this.contract.state === 'released') {
        router.push({ name: 'order-commitment-create' })
      } else {
        this.$swal({
          title: 'Não é possível criar empenho.',
          text: 'O contrato do pedido não está liberado, libere ele para prosseguir.',
          icon: 'error',
          showConfirmButton: false,
          timer: 5000,
        })
      }
    },
    editCommitment(item) {
      if (this.readonly) {
        router.push({
          name: 'order-commitment-detail',
          params: {
            idcommitment: item.id,
          },
        })
      } else {
        router.push({
          name: 'order-commitment-edit',
          params: {
            idcommitment: item.id,
          },
        })
      }
    },
    showConfirmOrCancelDelete(item) {
      this.$bvModal
        .msgBoxConfirm('Por favor, confirme que você quer remover o empenho.', {
          id: 'confirmation_box',
          title: 'Confirmar?',
          size: 'sm',
          okVariant: 'primary',
          okTitle: 'Sim',
          cancelTitle: 'Não',
          cancelVariant: 'outline-primary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if (value) {
            this.deleteCommitment(item)
          }
        })
    },
    deleteCommitment(item) {
      syslic
        .order
        .commitment
        .deleteCommitment(item)
        .then(() => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Empenho excluido com sucesso.',
              icon: 'EditIcon',
              variant: 'success',
            },
          })
          this.fetchData()
        })
        .catch(error => {
          let textError = 'Não foi possível remover o empenho do servidor, por favor entre em contato com o administrador do sistema.'

          if ('data' in error) {
            if (error.data.includes('they are referenced through protected foreign keys')) {
              textError = 'Este empenho é referenciado em alguma entrega, por este motivo não será possível remove-lo.'
            }
          }
          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Falha ao remover empenho.',
              text: textError,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              timeout: false,
            },
          })
        })
    },
    getAuditSvgClass(situation) {
      const svgClassMap = {
        audit: 'commitmentInAudit',
        declined: 'commitmentDeclined',
      }

      return svgClassMap[situation] || ''
    },
    setTooltipTitleAudit(situation) {
      const tooltipTitle = {
        audit: 'Entrega em Auditoria.',
        declined: 'Entrega Declinada.',
      }

      return tooltipTitle[situation] || ''
    },
    setTooltipTitleDone(situation) {
      const tooltipTitle = {
        done: 'Entrega Concluída.',
        idle: 'Entrega Aberta.',
      }

      return tooltipTitle[situation] || ''
    },
  },
  setup() {
    const toast = useToast()

    return {
      mdiPlus,
      mdiTrashCanOutline,
      mdiFileSearch,
      mdiCheckDecagram,
      moment,
      toast,
    }
  },
}
</script>

<style lang="scss">
@import "@core/scss/base/core/colors/palette-variables.scss";

.btnAddCommitment {
  position: absolute;
  right: 0;
  bottom: 0;
}

.commitmentCardItemBlock.left {
  border-left: 1px solid #ebe9f1;
}

.commitmentCardItemSectionContent h5 {
  font-weight: 400;
  font-size: 0.9rem;
  padding-top: 0.5rem;
}

.commitmentCardItemSectionTitle {
  font-weight: 600;
  font-size: 0.9rem;
  color: #005C96;
  margin-top: 0.75rem;
}

.statusSize {
  min-width: 110px !important;
  margin-top: 0.5rem;
}

.isEven {
  background-color: #fafafc;
}

.buttonList {
  border: none !important;
}

.commitmentListRow:hover {
  background: #f6f6f9 !important;
  cursor: pointer;
  box-shadow: 0 0 11px rgba(33, 33, 33, 0.2);
}

.commitmentIsDone {
  color: $success_2;
}

.commitmentInAudit {
  color: #FF730E;
}

.commitmentDeclined {
  color: #F50F00;
}
</style>
