<template>
  <order-deliveries-commitment-list
    :order-id="orderId"
    :router-from-order="true"
    :readonly="readonly"
    :can-remove="canRemove"
    @ready="syncLoad.ready('delivery')"
  />
</template>

<script>
import OrderDeliveriesCommitmentList from '@/views/order/delivery/OrderDeliveriesCommitmentList.vue'
import SyncLoad from '@/components/utils/syncLoad'

export default {
  components: {
    OrderDeliveriesCommitmentList,
  },
  props: {
    orderId: {
      type: [String, Number],
      required: true,
    },
    readonly: {
      type: [Boolean],
      required: true,
    },
    canRemove: {
      type: [Boolean],
      required: true,
    },
  },
  data() {
    const syncLoad = new SyncLoad()
    syncLoad.addTrigger('delivery')

    return {
      syncLoad,
    }
  },
  created() {
    this.$emit('ready', true)
  },
  methods: {
  },
  setup() {
    return {
    }
  },
}
</script>

<style lang="scss">
@import "@core/scss/base/core/colors/palette-variables.scss";

</style>
